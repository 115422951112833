import React, { useEffect, useState } from 'react';
import BasicLayout from '../../layout/BasicLayout/BasicLayout';
import Login from '../../components/Login/Login';
import { useParams, useHistory } from 'react-router-dom'
//Redux
import { connect } from 'react-redux'
import { consultadoUrl } from '../../_actions/formularioActions';
import Loading from '../../components/Loading';

const Home = props => {

  const history = useHistory()
  const [header, setHeader] = useState(true)
  const params = useParams()
  const { url } = params
  const { loading, formulario, logueado, consultadoUrl } = props

  useEffect(() => {
    consultadoUrl(url)
  }, [])

  useEffect(() => {
    if (!loading && formulario.id_tp_estado !== '1') {
      history.push('/error')
    }
  }, [formulario])

  useEffect(() => {
    if (logueado) {
      history.push(`/form/${url}/encuesta`)
    }
  }, [logueado])

  return ( 
    <BasicLayout
      title='Login'
      header={header}
    >
    {loading === true ? (
      <Loading/>
    ): (
    
        <Login/>
     
    )}
    </BasicLayout>
   );
}

const mapStateToProps = state => ({
  loading: state.formulario.loading,
  logueado: state.usuario.logueado,
  formulario: state.formulario.formulario
})

const mapDispatchToProps = {
  consultadoUrl
}
 
export default connect(mapStateToProps, mapDispatchToProps)(Home);