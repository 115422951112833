import {
  OBTENER_FOMR_LOGIN,
  OBTENER_FORM_LOGIN_EXITO,
  OBTENER_FORMULARIO,
  OBTENER_FORMULARIO_EXITO,
  GUARDAR_RESPUESTAS_EXITO,
  GUARDAR_RESPUESTAS,
  ACTUALIZAR_GUARDADO,
  OBTENER_ASESOR,
  OBTENER_PRODUCTO
} from "./index";

import { obtenerAsesor,obtenerProducto } from "../_reducers/formularioReducer";
import { authAxios } from "../config/authAxios";
import Swal from "sweetalert2";
const loading = (type) => {
  return {
    type,
  };
};

const updateActiveForm = (form) => {
  return {
    type: OBTENER_FORM_LOGIN_EXITO,
    payload: form,
  };
};

export const consultadoUrl = (url) => {
  return async (dispatch) => {
    dispatch(loading(OBTENER_FOMR_LOGIN));

    try {
      const response = await authAxios.get(
        `/admin/rptet_confirmacionUrl/${url}`
      );

    
      dispatch(updateActiveForm(response.data));
    } catch (error) {}
  };
};

const formularioObtenido = (form) => {
  return {
    type: OBTENER_FORMULARIO_EXITO,
    payload: form,
  };
};

export const obtenerFormulario = (url) => {
  const datos={
    url:url
  }
  return async (dispatch) => {
    dispatch(loading(OBTENER_FORMULARIO));

    try {
      const formulario = await authAxios.post(
        `/admin/obtenerFormularioContrato`,datos
      );

      if (!formulario.data.error) {
       dispatch(loading(OBTENER_FORMULARIO_EXITO));

       dispatch(obtenerAsesor(formulario.data.asesor));
       dispatch(obtenerProducto(formulario.data.proyecto));

       return formulario.data;
      }
      else{
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  };
};

const guardarRespuestaEnvio = () => ({
  type: GUARDAR_RESPUESTAS_EXITO
});

const actualizaStateGuardado = () => ({
  type: ACTUALIZAR_GUARDADO
})

export const guardarRespuestasForm = (data) => {
  return async (dispatch) => {
    dispatch(loading(GUARDAR_RESPUESTAS));

    try {
      const respuesta = await authAxios.post(
        `/admin/rptet_insertarCalificacionDiscente`,
        data
      );
      console.log(respuesta.data)
      if (!respuesta.data.error) {
        dispatch(guardarRespuestaEnvio(respuesta.data));
        // dispatch(push('/form/nombre-encuesta-98f21b30-ad1c-11ea-93f7-5fa81159983e/success'))
        dispatch(actualizaStateGuardado())
        return
      }
    } catch (error) {
      console.log(error)
    }
  };
};

export const obtenerProyectos = () => {
  return async (dispatch) => {

    try {
      const proyectos = await authAxios.get(
        `/admin/proyectosContrato`
      );

      if (!proyectos.data.error) {
       return proyectos.data;
      // return proyectos.data
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const obtenerAsesores = () => {
  return async (dispatch) => {

    try {
      const asesores = await authAxios.get(
        `/admin/obtenerAsesoresContrato`
      );

      if (!asesores.data.error) {
       return asesores.data;
      // return proyectos.data
      }
    } catch (error) {
      console.log(error);
    }
  };
};


export const guardarContrato = (data,setEnvioExito) => {
  return async dispatch => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger"
      },
      buttonsStyling: false
      });
      swalWithBootstrapButtons
      .fire({
          title: "¿Todos los datos son correctos?",
          text: "Se guardarán tus datos y se desactivará el formulario.",
          icon: "info",
          showCancelButton: true,
          confirmButtonText: "Si, continuar!",
          cancelButtonText: "No, cancelar!",
          reverseButtons: true,
          showLoaderOnConfirm: true,
          preConfirm: async function() {

                try {
                  const respuesta = await authAxios.post(
                    `/admin/insertarEstudiantePorContrato`,
                    data
                  );
                 
                 
                  if (!respuesta.data.error) {
                    return true;
                  }
                  else{
                    return false;
                  }
                
                } catch (error) {
                  console.log(error)
                }
          },
          allowOutsideClick: () => !Swal.isLoading()
      })
      .then(result => {
     
          if (result.value === true) {
          swalWithBootstrapButtons.fire(
              "Enviado!",
              "¡Gracias! sus datos fueron recibidos correctamente ",
              "success"
          );
            setEnvioExito(true);
          }
          else
          {
            swalWithBootstrapButtons.fire(
              "Lo Sentimos",
              "Ha ocurrido un error en el proceso, intente nuevamente.",
              "error"
          );
          }
      });
  }
};
