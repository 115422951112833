import {
  OBTENER_USUARIO,
  LOGIN_USUARIO_EXITOSO,
  LOGIN_USUARIO_ERROR,
  LOGOUT
} from "./index";

import { authAxios } from "../config/authAxios";

const loading = (type) => {
  return {
    type,
  };
};

const loginExitoso = usuario => {
  return {
    type: LOGIN_USUARIO_EXITOSO,
    payload: usuario
  }
}

const setError = error => {
  return {
    type: LOGIN_USUARIO_ERROR,
    payload: error
  }
}

export const obtenerUsuario = (url, data) => {
  return async dispatch => {
    dispatch(loading(OBTENER_USUARIO));

    try {
      const respuesta = await authAxios.post(`/admin/rptet_logueoUsuarioFormulario/${url}`, data);

      if (respuesta.data.mensaje) {
        dispatch( setError(respuesta.data.mensaje)  )
        return
      }

      if (!respuesta.data.error) {
        console.log(respuesta.data)
        dispatch( loginExitoso(respuesta.data.decode.datos[0]) )
        localStorage.setItem('token', JSON.stringify(respuesta.data.encode))
        return
      }

      dispatch( setError(respuesta.data.error)  )

    } catch (error) {
      console.log(error)
      dispatch( setError(error)  )
    }
  };
};


export const logout = () => {
  return dispatch => {
    dispatch( { type: LOGOUT } )
  }
}