import React from 'react';
import BasicLayout from '../../layout/BasicLayout/BasicLayout';
import ImgCheck from '../../assets/img/check.png';
import './ExitoEnvio.scss';
import { Image, Button } from 'react-bootstrap';
import { useParams, useHistory } from 'react-router-dom';

//redux
import { connect } from 'react-redux';
import { logout } from '../../_actions/usuarioActions';

const ExitoEnvio = props => {
  
  const history = useHistory()
  const params = useParams()
  const { url } = params

  const { logout } = props

  const logoutUser = () => {

    logout()
    history.push(`/form/${url}`)

  }
  
  return ( 
    <BasicLayout header={false}>
      <div className="exito-envio">
      <div className="main-mensaje shadow-sm bg-white">
        <div className="header-encuesta">
          <h1>ENVIADO</h1>
        </div>
        <div className="body-mensaje bg-white p-4">

          <div className="body-mensaje__mensaje">
              
              <div className="logo">
                  <Image src={ImgCheck}/>
                  <h5>¡Gracias! sus datos fueron recibidos correctamente </h5>
              </div>
             
              <p className='mt-5'>INEDI Posgrados®, 10 años de experiencia capacitando a más de 50 000 profesionales en todo el pais.</p>
{/* 
              <Button onClick={() => logoutUser()}>
                Salir
              </Button> */}
          </div>

        </div>
      </div>
      <div className="foot"> © {new Date().getFullYear()} INEDI Posgrados &reg; Privacy & Cookies</div>
      </div>
    </BasicLayout>
   );
}

const mapStateToProps = state => ({

}) 

export default connect( mapStateToProps , { logout })(ExitoEnvio);