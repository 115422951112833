import React from 'react';

const SelectPaises = props => {

    const {datos,primerSelect, name, handleChange} = props;


    return (
        <select className="form-control" name={name} onChange={handleChange} required>
            <option value="">{primerSelect}</option>
            {
                datos.map((dato,i)=>(
                  <option key={i} value={dato.PaisCodigo}>{dato.PaisNombre}</option>
                ))
            }
        </select>
    );
};

export default SelectPaises;