export const OBTENER_FOMR_LOGIN = 'OBTENER_FOMR_LOGIN';
export const OBTENER_FORM_LOGIN_EXITO = 'OBTENER_FORM_LOGIN_EXITO'
export const OBTENER_FORMULARIO = 'OBTENER_FORMULARIO' 
export const OBTENER_FORMULARIO_EXITO = 'OBTENER_FORMULARIO_EXITO'

export const OBTENER_USUARIO = 'OBTENER_USUARIO'
export const LOGIN_USUARIO_EXITOSO = 'LOGIN_USUARIO_EXITOSO'
export const LOGIN_USUARIO_ERROR = 'LOGIN_USUARIO_ERROR'

export const GUARDAR_RESPUESTAS = 'GUARDAR_RESPUESTAS'
export const GUARDAR_RESPUESTAS_EXITO = 'GUARDAR_RESPUESTAS_EXITO'
export const ACTUALIZAR_GUARDADO = 'ACTUALIZAR_GUARDADO'
export const LOGOUT = 'LOGOUT'

export const OBTENER_ASESOR = 'OBTENER_ASESOR'
export const REMOVER_ASESOR = 'REMOVER_ASESOR'

export const OBTENER_PRODUCTO = 'OBTENER_PRODUCTO'
export const REMOVER_PRODUCTO = 'REMOVER_PRODUCTO'