import { createStore, applyMiddleware, compose } from 'redux';
import persistState from 'redux-localstorage';
import thunk from 'redux-thunk';
import reducer from './_reducers';

import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'react-router-redux'

const history = createBrowserHistory()
const middleware = routerMiddleware(history)

const store = createStore(
  reducer,
  compose(
    persistState('usuario'),
    applyMiddleware(middleware, thunk)
  )
)

export default store;

// ,
//     window.__REDUX_DEVTOOLS_EXTENSION__ &&
//     window.__REDUX_DEVTOOLS_EXTENSION__()