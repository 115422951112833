import React from 'react';
import BasicLayout from '../../layout/BasicLayout';
import './Error404.scss';
import ImgCheck from '../../assets/img/404.png';
import { Image, Button } from 'react-bootstrap';
const Error404 = () => {
  return ( 
    <BasicLayout header={false}>
      <div className="exito-envio">
        <div className="main-mensaje shadow-sm bg-white">
          <div className="header-encuesta text-center">
            <h1>Formulario no Disponible</h1>
          </div>
          <div className="body-mensaje bg-white p-4">

            <div className="body-mensaje__mensaje">
                
                <div className="logo">
                    <Image src={ImgCheck}/>
                    <h5>Lo sentimos</h5>
                </div>
              
                <p className='mt-5 text-center'>No es posible acceder al formulario, por favor solicita un enlace a tu asesor.</p>
            </div>

          </div>
        </div>
        <div className="foot">© {new Date().getFullYear()} INEDI Posgrados &reg; Privacy & Cookies</div>
      </div>
    </BasicLayout>
   );
}
 
export default Error404;