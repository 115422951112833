import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { obtenerAsesores } from "../../_actions/formularioActions";

const Step_three = (props) => {

  const { datos, onChangeValue, onChangeCheck, enviarContrato, prevStep } = props;
  const [asesores, setAsesores] = useState([])
  const asesor_id = useSelector(state => state.formulario.asesor);
  const dispatch = useDispatch()
  const {
    medio_conocimiento,
    factor,
    momento_decision,
    nivel_de_materia_estudio,
    tiempo_capacitacion,
    horario_preferencia,
    terminos_condiciones_contrato,
    condiciones_cartilla,
    declaracion_sistema_tutoria,
    asesor } = datos;

  const handleSubmit = (e) => {
    e.preventDefault()
    enviarContrato(e)
  }

  useEffect(() => {
    if (asesor_id) {
      datos.asesor = asesor_id;
    }

    async function fetchData() {
      // You can await here
      const response = await dispatch(obtenerAsesores());
      setAsesores(response);
      // ...
    }
    if (!asesor_id) {
      fetchData();
    }
  }, [])

  const mostrarOpciones = opciones => {
    if (opciones) {
      return opciones.map((opcion, i) => (
        <option key={i} value={opcion.id_usuario}>{opcion.nombre_usuario}</option>
      ))
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="PerfilUsuario">
        <div className="content_datos py-1 px-1">
          <Row>
            <div className="col-lg-12 order-lg-2">
              <div className="tab-content pt-1">
                <div className="" id="edit">
                  <div className="form-group row">
                    <label className="col-lg-12 col-form-label form-control-label">
                      ¿Cómo te enteraste de la capacitación? *
                    </label>
                    <div className="col-lg-6">
                      <select
                        className="form-control"
                        name="medio_conocimiento"
                        value={medio_conocimiento}
                        onChange={onChangeValue}
                        required
                      >
                        <option value="">Seleccione una opción</option>
                        <option value="TikTok"> TikTok </option>
                        <option value="Facebook"> Facebook </option>
                        <option value="Instagram"> Instagram </option>
                        <option value="Youtube"> Youtube </option>
                        <option value="Linkedin"> Linkedin </option>
                        <option value="Twitter"> Twitter </option>
                        <option value="Buscadores"> Buscadores </option>
                        <option value="Email"> Email </option>
                        <option value="Asesor"> Asesor </option>
                        <option value="Página Web">Página Web </option>
                        <option value="Publicidad Física">Publicidad Física</option>

                      </select>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-lg-12 col-form-label form-control-label">
                      ¿Qué factor influyó para decidir tu compra? *
                    </label>
                    <div className="col-lg-6">
                      <select
                        className="form-control"
                        name="factor"
                        value={factor}
                        onChange={onChangeValue}
                        required
                      >
                        <option value="">Seleccione una opción</option>
                        <option value="Prestigio de la marca"> Me gusta la propuesta de Inedi. </option>
                        <option value="Docentes"> Me gustan los profesores. </option>
                        <option value="Malla curricular">Me gustan los temas que desarrollaremos.</option>

                      </select>
                    </div>
                  </div>
                  {!asesor_id && (<div className="form-group row">
                    <label className="col-lg-12 col-form-label form-control-label">
                      ¿Quién te asesoró?:*
                    </label>
                    <div className="col-lg-6">
                      <select
                        className="form-control"
                        name="asesor"
                        value={asesor}
                        onChange={onChangeValue}
                        required
                      >
                        <option value="">Seleccione una opción</option>
                        {asesores ? mostrarOpciones(asesores) : ''}

                      </select>
                    </div>
                  </div>)}
                  <div className="form-group row">
                    <label className="col-lg-12 col-form-label form-control-label">
                      ¿Cuándo decidiste tu compra?:*
                    </label>
                    <div className="col-lg-6">
                      <select
                        className="form-control"
                        name="momento_decision"
                        value={momento_decision}
                        onChange={onChangeValue}
                        required
                      >
                        <option value="">Seleccione una opción</option>
                        <option value="Antes de conversar con el asesor."> Antes de conversar con el asesor. </option>
                        <option value="Después de conversar con el asesor."> Después de conversar con el asesor. </option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-lg-12 col-form-label form-control-label">
                      ¿En qué nivel estás?: *
                    </label>
                    <div className="col-lg-6">
                      <select
                        className="form-control"
                        name="nivel_de_materia_estudio"
                        value={nivel_de_materia_estudio}
                        onChange={onChangeValue}
                        required
                      >
                        <option value="">Seleccione una opción</option>
                        <option value="Básico"> Básico </option>
                        <option value="Intermedio"> Intermedio </option>
                        <option value="Avanzado"> Avanzado </option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-lg-12 col-form-label form-control-label">
                      ¿Qué quieres aprender próximamente?:*
                    </label>
                    <div className="col-lg-8">
                      <textarea className="form-control"
                        name="tema_de_preferencia"
                        onChange={onChangeValue} required></textarea>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-lg-12 col-form-label form-control-label">
                      En función a la pregunta anterior: ¿en qué tiempo te gustaría estudiar tu nueva capacitación?*
                    </label>
                    <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: '30px' }} className="col-lg-6 form-check" onChange={onChangeValue}>
                      <div> <input id="optct" className="form-check-input" type="radio" value="Capacitación corta" name="tiempo_capacitacion" defaultChecked={tiempo_capacitacion === '1' ? true : false} required /> <label htmlFor={'optct'}>Capacitación corta </label></div>
                      <div> <input id="optes" className="form-check-input" type="radio" value="Especialización" name="tiempo_capacitacion" defaultChecked={tiempo_capacitacion === '2' ? true : false} required /> <label htmlFor={'optes'}>Especialización </label></div>
                    </div>
                  </div>
                  {/*<div className="form-group row">
                      <label className="col-lg-12 col-form-label form-control-label">
                      ¿A qué hora prefieres que te llamen?*
                      </label>
                      <div className="col-lg-6">
                      <select
                          className="form-control"
                          name="horario_preferencia"
                          value={horario_preferencia}
                          onChange={onChangeValue}
                          required
                        >
                        <option value="">Seleccione una opción</option>  
                        <option value="09:00am - 11:00pm"> En la mañana, antes de las 11 A.m.</option>
                        <option value="11:00pm - 01:00pm "> Al medio día, antes de la 1 P.m.</option>
                        <option value="05:00pm - 06:00pm "> Pasada la tarde, después de las 5:00 P.m.</option>
                      </select>
                      </div>
                    </div>*/}
                  <div className="form-group row" style={{ marginTop: "65px" }}>
                    <div className="col-lg-8">
                      <div className="col-lg-1">
                        <input id="chktrm" className="form-check-input" type="checkbox" name="terminos_condiciones_contrato" onChange={onChangeCheck} checked={terminos_condiciones_contrato} required />
                      </div>
                      <label style={{ marginTop: "-15px" }} className="col-lg-11 col-form-label form-control-label" htmlFor={'chktrm'}>
                        Conozco y acepto los <a rel="noopener noreferrer" href="https://drive.google.com/file/d/1XCtWeh_aEg7o6Zz6GUOMIfFnWKvifx5c/view?usp=sharing" target="_blank">términos y condiciones del servicio*</a>.
                      </label>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-8">
                      <div className="col-lg-1">
                        <input id="chkcnt" className="form-check-input" type="checkbox" name="condiciones_cartilla" onChange={onChangeCheck} checked={condiciones_cartilla} required />
                      </div>
                      <label style={{ marginTop: "-15px" }} htmlFor={'chkcnt'} className="col-lg-11 col-form-label form-control-label">
                        Conozco y acepto los <a rel="noopener noreferrer" href="https://drive.google.com/file/d/1UcDU9ObSywDfmn00YkIQn00ccSLPhb28/view?usp=sharing" target="_blank">términos y condiciones de la inversión*</a>.
                      </label>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-8">
                      <div className="col-lg-1">
                        <input id="chktut" className="form-check-input" type="checkbox" name="declaracion_sistema_tutoria" onChange={onChangeCheck} checked={declaracion_sistema_tutoria} required />
                      </div>
                      <label style={{ marginTop: "-15px" }} htmlFor={'chktut'} className="col-lg-11 col-form-label form-control-label">
                        Conozco y acepto los <a rel="noopener noreferrer" href="https://drive.google.com/file/d/17vmKrYZtiD0ZSSVExDi1uUHBDbTeMJvV/view?usp=sharing" target="_blank">términos y condiciones de la experiencia*</a>.
                      </label>
                    </div>
                  </div>
                  <div className="form-group row mt-6">
                    <div className="col-lg-12">
                      <span style={{ fontSize: "8pt" }}>Gestora® administra los datos de sus clientes de acuerdo a la Ley N° 29733 - Ley de Protección de Datos Personales, su Reglamento aprobado mediante Decreto Supremo N° 003-2013-JUS y las demás disposiciones complementarias. <a rel="noopener noreferrer" href="http://www.leyes.congreso.gob.pe/Documentos/Leyes/29733.pdf" target="_blank">http://www.leyes.congreso.gob.pe/Documentos/Leyes/29733.pdf</a></span>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="form-group row d-flex justify-content-between"></label>
                    <div className="btn_datos col-lg-12">
                      <button className="btn btn-primary" onClick={() => prevStep()} >Anterior</button>
                      <button type="submit" className="btn btn-primary btn-submit float-right">
                        Enviar información
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </div>
      </div>
    </form>
  );
};

export default Step_three;
