import React, { useEffect, useState } from "react";
import { ToastsStore } from 'react-toasts';
import StepOne from "./Step_one";
import {
  guardarContrato,
} from "../../_actions/formularioActions";
import { useDispatch } from "react-redux";
import StepTwo from "./Step_two";
import StepThree from "./Step_three";
import { useHistory } from "react-router";
const Perfil = (props) => {

  const initialState = {
    url: props.url,
    tipo_imagen: "",
    nombres: "",
    apellidos: "",
    fecha_nac: "",
    correo: "",
    dni: "",
    direccion: "",
    distrito: "",
    provincia: "",
    departamento: "",
    pais: "",
    celular: "",
    celular_ref: "",
    gender: "",
    id_estado: 2,
    proyecto: "",
    modalidad: "",
    inversion: "",
    fecha_proxima_inversion: "",
    monto_proxima_inversion: "",
    tipo_de_compra: "",
    fecha_inicio_membresia: "",
    fecha_fin_membresia: "",
    metodo_pago: 0,
    monto_pago: "",
    datos_imagen: "",
    n_operacion: "",
    nombre_facturacion: "",
    tipo_comprobante: "",
    ruc: "",
    carrera: "",
    grado_academico: "",
    sector_desempeno: "",
    area_labor: "",
    medio_conocimiento: "",
    factor: "",
    momento_decision: "",
    nivel_de_materia_estudio: "",
    tema_de_preferencia: "",
    tiempo_capacitacion: "",
    horario_preferencia: "",
    asesor: "",
    razon_social: "",
    terminos_condiciones_contrato: false,
    condiciones_cartilla: false,
    declaracion_sistema_tutoria: false
  }
  //state for steps
  const [step, setstep] = useState(1);

  //state for form data
  const [formData, setFormData] = useState(initialState)

  const dispatch = useDispatch();
  const [envioExito, setEnvioExito] = useState(false);
  const history = useHistory();

  // function for going to next step by increasing step state by 1
  const nextStep = () => {
    setstep(step + 1);
  };

  // function for going to previous step by decreasing step state by 1
  const prevStep = () => {
    setstep(step - 1);
  };




  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step])

  useEffect(() => {
    if (envioExito) {
      setFormData({ ...initialState, })
      history.push(`/form/${props.url}/success`)
    }
  }, [envioExito])


  const setFechaInicio = (f) => {
    setFormData({
      ...formData,
      fecha_inicio_membresia: f
    });
  }
  const setFechaFin = (f) => {
    setFormData({
      ...formData,
      fecha_fin_membresia: f
    });
  }
  const onChangeValue = (e) => {
    // console.log(e.target.name,e.target.value)
    if (e.target.name === "departamento" && (formData.provincia !== "" && formData.distrito !== ""))
      setFormData({
        ...formData,
        provincia: "",
        distrito: ""
      });
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  }
  const onChangeCheck = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.checked
    });
  }
  const onChangeImage = (e) => {

    let reader = new FileReader(), file = e.target.files[0],
      pattern = /image\/[a-z]{3}|application\/pdf/;

    if (file !== undefined) {
      const maxSize = 5 * 1024 * 1024; // 5 MB en bytes
      if (!file.type.match(pattern)) {
        ToastsStore.error("No es un archivo válido. Por favor use archivos (png o jpg)")
        e.target.value = ''
        return;
      } else if (file.size > maxSize) {
        ToastsStore.error("El archivo es muy grande. Por favor use archivos menores a 5MB")
        e.target.value = ''
        return;
      }

      reader.onloadend = () => {
        setFormData({
          ...formData,
          datos_imagen: reader.result,
          tipo_imagen: file.type
        })
      }

      reader.readAsDataURL(file);
    }
    else {

      return;
    }
  }
  //EDITAR USUARIO
  const enviarContrato = async (e) => {
    dispatch(guardarContrato(formData,setEnvioExito))
  };


  switch (step) {
    case 1:
      return (
        <StepOne datos={formData} onChangeValue={onChangeValue} datosSelect={props.datos} nextStep={nextStep} />
      );
    // Only formData is passed as prop to show the final value at form submit
    case 2:
      return (
        <StepTwo datos={formData} onChangeValue={onChangeValue} onChangeImage={onChangeImage} setFechaInicio={setFechaInicio} setFechaFin={setFechaFin} nextStep={nextStep} prevStep={prevStep} />
      );
    // default case to show nothing
    case 3:
      return (
        <StepThree datos={formData} onChangeValue={onChangeValue} onChangeCheck={onChangeCheck} prevStep={prevStep} enviarContrato={enviarContrato} />
      );
  };
}
export default Perfil;
