import React from 'react';

const HeadEncuesta = ({ title = '', descripcion = '' }) => {
  return ( 
    <div className="head-encuesta">
       <div className="header-encuesta px-2 px-md-5 text-center">
          <h1>{title}</h1>
          <p>{descripcion}</p>
        </div>
    </div>
   );
}
 
export default HeadEncuesta;