import React from 'react';
import { Image } from 'react-bootstrap'
import Logo from '../../assets/img/logo/logo_blanco.png';
import './Header.scss';

const Header = () => {
  return ( 
    <header className='header' >
      <Image src={Logo} />
    </header>
   );
}
 
export default Header;