import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import SelectDepartamentos from "./SelectDepartamentos";
import SelectProvincias from "./SelecProvincias";
import SelectDistritos from "./SelectDistritos";
import SelectPaises from "./SelectPaises";

const Step_one = (props) => {

  const {datos,onChangeValue,datosSelect,nextStep} = props;

  const {nombres,
         apellidos,
         fecha_nac,
         correo,
         dni,
         direccion,
         distrito,
         provincia,
         departamento,
         pais,
         celular,
         celular_ref,
         gender,   
         carrera,
         grado_academico,
         sector_desempeno,
         area_labor,} = datos;
  const [departamentos,setDepartamentos]=useState(null)
  const [provincias,setProvincias]=useState(null)
  const [distritos,setDistritos]=useState(null)
  const[dist,setDist]=useState(distrito)



  const handleSubmit = (e)=>{
    e.preventDefault()

    nextStep()

  }

  const llenarDepartamentos= (pais) =>
  {
    const copiaDepartamentos = [...datosSelect.departamentos];
   // console.log(copiaDepartamentos)
    const arregloActualizado = copiaDepartamentos.filter(opcion => opcion.pais === pais);
    setDepartamentos(arregloActualizado);
  }


  const llenarProvincias= (depa) =>
  {
    const copiaProvincias = [...datosSelect.provincias];
    //console.log(copiaProvincias)
    const arregloActualizado = copiaProvincias.filter(opcion => opcion.idDepa == depa);
    setProvincias(arregloActualizado);
  }

  const llenarDistritos= (prov) =>
  {
    const copiaDistritos = [...datosSelect.distritos];
    //console.log(copiaProvincias)
    const arregloActualizado = copiaDistritos.filter(opcion => opcion.idProv == prov);
    setDistritos(arregloActualizado);
  }

  useEffect(()=>{
    if(pais!=="")
    {
    //  console.log(pais)
      setDepartamentos(null)
      setProvincias(null);
      setDistritos(null)
      setDist("");
      llenarDepartamentos(pais);
    }
  },[pais])

  useEffect(()=>{
    if(departamento!=="")
    {
      setProvincias(null);
      setDistritos(null)
      setDist("");
      llenarProvincias(departamento);
    }
  },[departamento])


  useEffect(()=>{
    if(provincia!=="")
    {
      llenarDistritos(provincia);
    }
  },[provincia])

  return (
      <form onSubmit={handleSubmit}>
      <div className="PerfilUsuario">
        <div className="content_datos py-1 px-1">
       
          <Row>
            <div className="col-lg-12 order-lg-2">
              <div className="tab-content pt-1">
                <div className="" id="edit">
                
                    <div className="form-group row">
                      <label className="col-lg-3 col-form-label form-control-label">
                        Nombres:* 
                      </label>
                      <div className="col-lg-9">
                        <input
                          className="form-control"
                          name="nombres"
                          type="text"
                          placeholder="Nombres"
                          value={nombres}
                          onChange={onChangeValue}
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-lg-3 col-form-label form-control-label">
                        Apellidos:*
                      </label>
                      <div className="col-lg-9">
                        <input
                          className="form-control"
                          type="text"
                          name="apellidos"
                          placeholder="Apellidos"
                          value={apellidos}
                          onChange={onChangeValue}
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-lg-3 col-form-label form-control-label">
                        Fecha Nac.:*
                      </label>
                      <div className="col-lg-9">
                        <input
                          className="form-control"
                          type="date"
                          name="fecha_nac"
                          value={fecha_nac}
                          onChange={onChangeValue}
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-lg-3 col-form-label form-control-label">
                        Género:*
                      </label>
                      <div className="col-lg-9 form-check d-flex justify-content-around flex-wrap" onChange={onChangeValue} required>
                          <div style={{lineHeight:'25px', width:'120px'}}> <input id="optm" className="form-check-input" type="radio" value="M" name="gender" defaultChecked={gender==='M' ? true : false} /> <label htmlFor={'optm'}>Masculino </label></div> 
                          <div style={{lineHeight:'25px', width:'120px'}}> <input id="optf" className="form-check-input" type="radio" value="F" name="gender" defaultChecked={gender==='F' ? true : false} /> <label htmlFor={'optf'}>Femenino </label></div>
{/*                           <div style={{lineHeight:'25px', width:'120px'}}> <input id="optn" className="form-check-input" type="radio" value="G" name="gender" defaultChecked={gender==='G' ? true : false} /> <label htmlFor={'optn'}>Gay </label></div>
                          <div style={{lineHeight:'25px', width:'120px'}}> <input id="optl" className="form-check-input" type="radio" value="L" name="gender" defaultChecked={gender==='L' ? true : false} /> <label htmlFor={'optl'}>Lesbiana </label></div>
 */}                          <div style={{lineHeight:'25px', width:'120px'}}> <input id="opto" className="form-check-input" type="radio" value="O" name="gender" defaultChecked={gender==='O' ? true : false} /> <label htmlFor={'opto'}>Otros</label></div>
                          {
                           gender==='O' ? <input id="optn" className="form-control" type="text" name="otros_txt" /> : null
                          }     
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-lg-3 col-form-label form-control-label">
                        Correo:*
                      </label>
                      <div className="col-lg-9">
                        <input
                          className="form-control"
                          type="email"
                          name="correo"
                          placeholder="Correo Electrónico"
                          value={correo}
                          onChange={onChangeValue}
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-lg-3 col-form-label form-control-label">
                        (DNI/CE):*
                      </label>
                      <div className="col-lg-9">
                        <input
                          className="form-control"
                          name="dni"
                          type="text"
                          placeholder="Documento de Identidad"
                          value={dni}
                          onChange={onChangeValue}
                          pattern="[0-9]{8,21}"
                          title="Debe ingresar un documento válido. DNI o Carné de extranjería"
                          required
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-lg-3 col-form-label form-control-label">
                        Dirección:*
                      </label>
                      <div className="col-lg-9">
                        <input
                          className="form-control"
                          type="text"
                          name="direccion"
                          placeholder="Ejm: Av. La Victoria #225"
                          value={direccion}
                          onChange={onChangeValue}
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-lg-12 col-form-label form-control-label">Lugar de residencia:*</label>
                      <div className="col-lg-4 mb-1">
                       { datosSelect ? <SelectPaises name="pais" datos={datosSelect.paises} handleChange={onChangeValue} primerSelect="Seleccione un Pais" /> : ''}
                      </div>
                      <div className="col-lg-4 mb-1">
                       { departamentos ? <SelectDepartamentos name="departamento" datos={departamentos} handleChange={onChangeValue} primerSelect="Seleccione un Departamento" /> : ''}
                      </div>
                      <div className="col-lg-4 mb-1">
                      { provincias && provincias.lenght!==0 ? <SelectProvincias value={provincia} name="provincia" datos={provincias} handleChange={onChangeValue} primerSelect="Seleccione una provincia" /> : ''}
                      </div>
                      <div className="col-lg-4 mb-1">
                      { distritos && distritos.lenght!==0 ? <SelectDistritos value={distrito} name="distrito" datos={distritos} handleChange={onChangeValue} primerSelect="Seleccione un distrito" /> : ''}
                    
                    </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-lg-3 col-form-label form-control-label">
                        Celular:*
                      </label>
                      <div className="col-lg-9">
                        <input
                          className="form-control"
                          name="celular"
                          type="text"
                          placeholder="Teléfono de contacto"
                          value={celular}
                          onChange={onChangeValue}
                          pattern="[0-9]{9}"
                          title="El número debe tener 9 dígitos"
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-lg-3 col-form-label form-control-label">
                        Celular Ref.:*
                      </label>
                      <div className="col-lg-9">
                        <input
                          className="form-control"
                          name="celular_ref"
                          type="text"
                          placeholder="Teléfono alternativo"
                          value={celular_ref}
                          onChange={onChangeValue}
                          pattern="[0-9]{9}"
                          title="El número debe tener 9 dígitos"
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-lg-3 col-form-label form-control-label">
                      Profesión:*
                      </label>
                      <div className="col-lg-9">
                      <select
                          className="form-control"
                          name="carrera"
                          value={carrera}
                          onChange={onChangeValue}
                          required
                        >
                        <option value="">Seleccione una opción</option>
                        <option value="Administración de empresas">Administración de empresas</option>
                        <option value="Agronomía">Agronomía</option>
                        <option value="Ciencias">Ciencias</option>
                        <option value="Ciencias de la educación y humanidades">Ciencias de la educación y humanidades</option>
                        <option value="Ciencias de la comunicación, turismo y arqueología">Ciencias de la comunicación, turismo y arqueología</option>
                        <option value="Derecho y ciencias políticas">Derecho y ciencias políticas</option>
                        <option value="Economía">Economía</option>
                        <option value="Contabilidad">Contabilidad</option>
                        <option value="Enfermería">Enfermería</option>
                        <option value="Farmacia y bioquímica">Farmacia y bioquímica</option>
                        <option value="Ingeniería civil">Ingeniería civil</option>
                        <option value="Ingeniería mecánica, eléctrica, electrónica y sistemas">Ingeniería mecánica, eléctrica, electrónica y sistemas</option>
                        <option value="Ingeniería de minas y metalurgia">Ingeniería de minas y metalurgia</option>
                        <option value="Ingeniería química">Ingeniería química</option>
                        <option value="Ingeniería pesquera y de alimentos">Ingeniería pesquera y de alimentos</option>
                        <option value="Medicina humana">Medicina humana</option>
                        <option value="Medicina veterinaria y zootecnia">Medicina veterinaria y zootecnia</option>
                        <option value="Odontología">Odontología</option>
                        <option value="Arquitectura">Arquitectura</option>
                        <option value="Psicología">Psicología</option>
                        <option value="Negocios internacionales">Negocios internacionales</option>
                        <option value="Ingeniería industrial">Ingeniería industrial</option>
                        <option value="Marketing">Marketing</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-lg-3 col-form-label form-control-label">
                      Grado:*
                      </label>
                      <div className="col-lg-9">
                      <select
                          className="form-control"
                          name="grado_academico"
                          value={grado_academico}
                          onChange={onChangeValue}
                          required
                        >
                        <option value="">Seleccione una opción</option>
                        <option value="Doctor">Doctor</option>
                        <option value="Magister">Magister</option>
                        <option value="Profesional">Profesional</option>
                        <option value="Técnico">Técnico</option>
                        <option value="Estudiante">Estudiante</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-lg-3 col-form-label form-control-label">
                      Sector:*
                      </label>
                      <div className="col-lg-9">
                      <select
                          className="form-control"
                          name="sector_desempeno"
                          value={sector_desempeno}
                          onChange={onChangeValue}
                          required
                        >
                        <option value="">Seleccione una opción</option>
                        <option value="Independiente">Independiente</option>
                        <option value="Dependiente en el estado">Sector Público</option>
                        <option value="Dependiente en el privado">Sector Privado</option>
                        <option value="Estudiante">Estudiante</option>
                        <option value="No Trabaja">No Trabaja</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-lg-3 col-form-label form-control-label">
                      Area:* 
                      </label>
                      <div className="col-lg-9">
                      <select
                          className="form-control"
                          name="area_labor"
                          value={area_labor}
                          onChange={onChangeValue}
                          required
                        >
                        <option value="">Seleccione una opción</option>  
                        <option value="Directiva"> Directivo - CEO o propietario de una compañía </option>
                        <option value="Ejecutiva"> Ejecutivo - Lider con personas a su cargo </option>
                        <option value="Operativa"> Operativo - Profesional con tareas específicas </option>
                        <option value="Estudiante"> Estudiante </option>
                        <option value="No trabaja"> No trabaja </option>
    
                        </select>
                      </div>
                    </div>
                    <div className="form-group row d-flex justify-content-end">
                      <button className="btn btn-primary">Siguiente</button>
                    </div>
                </div>
              </div>
            </div>
          </Row>
        </div>
      </div>
      </form>
  );
};

export default Step_one;
