import React, { useState } from 'react';
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';

import './SimpleRating.scss'

export default function SimpleRating(props) {

  const { getStars, index } = props

  const [value, setValue] = useState(0);

  const setStars = newValue => {
    setValue(newValue)
    console.log(newValue)
    if (getStars !== undefined) {
      getStars(newValue || 0)
    }
  }

  return (
    <div className='simple-rating'>
      <Box component="fieldset" mb={3} borderColor="transparent">
        <Rating
          name={`simple-controlled-${index}`}
          value={value}
          size="large"
          onChange={(_, newValue) => {
            setStars(newValue);
          }}
        />
      </Box>
      
    </div>
  );
}