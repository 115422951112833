import { 
  OBTENER_FOMR_LOGIN,
  OBTENER_FORM_LOGIN_EXITO,
  OBTENER_FORMULARIO,
  OBTENER_FORMULARIO_EXITO,
  GUARDAR_RESPUESTAS_EXITO,
  ACTUALIZAR_GUARDADO,
  OBTENER_ASESOR,
  OBTENER_PRODUCTO,
  REMOVER_ASESOR,
  REMOVER_PRODUCTO
 } from '../_actions';

const initialState = {
  formulario: {},
  producto:null,
  asesor:null,
  envioForm: false,
  loading: true,
  error: null
}
export const obtenerAsesor = (asesor) => {
  return {
    type: OBTENER_ASESOR,
    payload: asesor
  }
}
export const removerAsesor = () => {
  return {
    type: REMOVER_ASESOR
  }
}
export const obtenerProducto = (producto) => {
  return {
    type: OBTENER_PRODUCTO,
    payload: producto
  }
}
export const removerProducto = () => {
  return {
    type: REMOVER_PRODUCTO
  }
}

export default function(state = initialState , action) {
  switch (action.type) {
    case OBTENER_FOMR_LOGIN:
      return {
        ...state,
        loading: true,
        error: null
      }
    case OBTENER_FORMULARIO:
      return {
        ...state,
        loading: true,
      }
    case OBTENER_FORMULARIO_EXITO:
      return {
        ...state,
        loading: false,
      }
    case OBTENER_FORM_LOGIN_EXITO:
      return {
        ...state,
        loading: false,
        formulario: action.payload
      }
    case GUARDAR_RESPUESTAS_EXITO:
      return {
        ...state,
        envioForm: true
      }
    case ACTUALIZAR_GUARDADO:
      return {
        ...state,
        envioForm: false
      }
    case OBTENER_ASESOR:
      return {
        ...state,
        asesor: action.payload
      }
    case REMOVER_ASESOR:
      return {
        ...state,
        asesor: null
      }
    case OBTENER_PRODUCTO:
      return {
        ...state,
        producto: action.payload
      }
    case REMOVER_PRODUCTO:
      return{
        ...state,
        producto: null
      }
    default:
      return state;
  }
}