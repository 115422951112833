import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";

import "./SubPreguntas.scss";

const SubPregunta = ({ propuesta, respuesta, handleChange }) => {
  return (
    <Form.Group className='pregunta'>
      <p>{propuesta.nombre_tp_tema_encuesta}</p>
      <Form.Control
        as="textarea"
        placeholder='Escriba su respuesta'
        onChange={(e) => handleChange(e.target.value, propuesta.id_propuesta)}
        value={respuesta || ''}
      />
    </Form.Group>
  );
};

const SubPeguntas = ({ propuestas, guardarPropuesta }) => {
  const [infoPropuesta, setInfoPropuesta] = useState(propuestas);
  const idPreguntaReporte = propuestas[0].id_pregunta_reporte

  const handleChange = (value, id) => {
    const copiaPrepuestas = [...infoPropuesta];

    copiaPrepuestas.map((item) => {
      if (item.id_propuesta === id) {
        item.propuesta = value;
      }
    });

    setInfoPropuesta(copiaPrepuestas);
  };

  useEffect(() => {
    guardarPropuesta(idPreguntaReporte, infoPropuesta)
  }, [infoPropuesta])

  return (
    <div className="lista-respuesta">
      {propuestas.map((propuesta, index) => (
        <SubPregunta
          key={propuesta.id_propuesta}
          respuesta={infoPropuesta[index].propuesta}
          propuesta={propuesta}
          handleChange={handleChange}
        />
      ))}
    </div>
  );
};

export default SubPeguntas;
