import React, { useState } from "react";
import LogoForm from "../../assets/img/form.png";
import "./Login.scss";
import { useParams } from 'react-router-dom'
import { Form, Button, Image, Spinner, Alert } from "react-bootstrap";

//Redux
import { connect } from "react-redux";
import { obtenerUsuario } from '../../_actions/usuarioActions';

const Login = (props) => {

  const params = useParams()
  const { url } = params

  const { formulario, loading, error, obtenerUsuario } = props;

  const [infoUsuario, setInfoUsuario] = useState(initialState());

  const { usuario, password } = infoUsuario;

  const handleChange = (e) => {
    setInfoUsuario({
      ...infoUsuario,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = e => {
    e.preventDefault()

    obtenerUsuario(url, infoUsuario)
  }

  return (
    <div className="login">
      <div className="main-form shadow">
        <div className="header-form text-center">
          <h1>Verificación</h1>
        </div>
        <div className="body bg-white p-4">
          <p className="text-justify">
            Has recibido un enlace a una encuesta que requiere verificación de
            usuario.
          </p>
          <div className="nombre-form d-flex">
            <Image src={LogoForm} />
            <h3 className="text-center">{formulario.nombre_formulario}</h3>
          </div>

          <p className="text-justify">
            Para abrir el contenido, ingrese su usuario y contraseña. Si no
            cuenta con ello, pregúntele a la persona que compartió el enlace con
            usted.
          </p>
          <Form onSubmit={onSubmit}>
            <Form.Control
              type="text"
              placeholder="Usuario"
              name='usuario'
              required
              value={usuario}
              onChange={handleChange}
            />
            <Form.Control
              type="password"
              placeholder="Contraseña"
              name='password'
              required
              password={password}
              onChange={handleChange}
            />
            { error && (
              <Alert variant='danger' className='error'>
                {error}
              </Alert>
            ) }
            <Button type="submit" variant="" disabled={loading} className="btn-block btn-submit">
              { loading && <Spinner animation="border"/>}
              Verificar
            </Button>
          </Form>
        </div>
      </div>
      <div className="foot">© 2020 INEDI Posgrados &reg; Privacy & Cookies</div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  formulario: state.formulario.formulario,
  loading: state.usuario.loading,
  loguedo: state.usuario.logueado,
  error: state.usuario.error
});

const mapDispatchToProps = {
  obtenerUsuario
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);

const initialState = () => ({
  usuario: "",
  password: "",
});
