import React from 'react';

const SelectDistritos = props => {

    const {datos,primerSelect, name, handleChange, value} = props;


    return (
        <select className="form-control" value={value} name={name} onChange={handleChange} required >
            <option value="">{primerSelect}</option>
            {
                datos.map((dato,i)=>(
                  <option key={i} value={dato.idDist}>{dato.distrito}</option>
                ))
            }
        </select>
    );
};

export default SelectDistritos;