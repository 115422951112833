import React, { useEffect, useState } from "react";
import "./FormularioDiscente.scss";
import { Form, Button } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";

//Redux
import { connect } from "react-redux";
import {
  obtenerFormulario,
  guardarRespuestasForm,
} from "../../_actions/formularioActions";
import ListaPreguntas from "../ListaPreguntasDiscentes";


const FormularioDiscente = props => {

  const {
    formulario,
    envioForm,
    guardarRespuestasForm,
  } = props;

  const history = useHistory();
  const params = useParams();
  const { url } = params;

  const [respuestas, setRepuestas] = useState([]);

  const initialRespuestas = () => {
    let copiaPreguntas = [...formulario.preguntas];
    let nuevaArrayRespuesta = new Array();

    copiaPreguntas.map((pregunta) => {
      nuevaArrayRespuesta = [
        ...nuevaArrayRespuesta,
        {
          id_pregunta_reporte: pregunta.id_pregunta_reporte,
          contenido: "",
          id_tp_pregunta: pregunta.id_tp_pregunta,
          propuestas: pregunta.propuestas
        },
      ];
    });

    setRepuestas(nuevaArrayRespuesta);
  };

  const guardarRespuestas = (id, valor) => {
    const copiaRespuestas = [...respuestas];

    copiaRespuestas.map((respuesta) => {
      if (respuesta.id_pregunta_reporte === id) {
        respuesta.contenido = valor;
      }
    });

    setRepuestas(copiaRespuestas);
  };

  const guardarPropuestas = (id, propuestas) => {
    const copiaRespuestas = [...respuestas];

    copiaRespuestas.map((respuesta) => {
      if (respuesta.id_pregunta_reporte === id) {
        respuesta.propuestas = propuestas;
      }
    });

    setRepuestas(copiaRespuestas);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    respuestas.map(respuesta => {
      if (respuesta.propuestas === undefined) {
        delete (respuesta.propuestas)
      }
    })

    const dataEnvio = {
      id_formulario: formulario.id_formulario,
      respuestas: respuestas,
    };
    console.log(dataEnvio)
    guardarRespuestasForm(dataEnvio);
  };

  useEffect(() => {
    if (formulario.preguntas !== undefined) {
      initialRespuestas();
    }
  }, [formulario]);

  useEffect(() => {
    if (envioForm) {
      history.push(`/form/${url}/success`);
    }
  }, [envioForm]);


  return (
    <div className="formulario-discente">
      <div className="mensaje-discente">
        <p className="text-required mt-2">Obligatorio</p>
      </div>
      <div className="body-encuesta__pregunta">
        <Form onSubmit={onSubmit}>
          {formulario.preguntas !== undefined && (
            <ListaPreguntas 
              guardarRespuestas={guardarRespuestas}
              guardarPropuestas={guardarPropuestas}
            />
          )}

          <Form.Group className="botones">
            <Button type="submit">Enviar</Button>
          </Form.Group>
        </Form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  formulario: state.formulario.formulario,
  envioForm: state.formulario.envioForm,
});

const mapDispatchToProps = {
  obtenerFormulario,
  guardarRespuestasForm,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormularioDiscente);
