import axios from 'axios';

const accessToken = localStorage.getItem('token')
const apiUrl = 'https://apisistema.gestoraperu.pe';

export const authAxios = axios.create({
  baseURL: apiUrl,
  headers: {
    Authorization: `Bearer ${accessToken}`
  } 
})