import {
  OBTENER_USUARIO,
  LOGIN_USUARIO_EXITOSO,
  LOGIN_USUARIO_ERROR,
  LOGOUT
} from "../_actions/index";

const initialState = {
  usuario: {},
  logueado: false,
  loading: false,
  error: null
}

export default function(state = initialState, action) {
  switch (action.type) {
    case OBTENER_USUARIO:
      return {
        ...state,
        loading: true,
        error: null
      }
    case LOGIN_USUARIO_EXITOSO:
      return {
        ...state,
        loading: false,
        error: null,
        usuario: action.payload,
        logueado: true
      }
    case LOGIN_USUARIO_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case LOGOUT:
      return {
        usuario: {},
        logueado: false,
        loading: false,
        error: null
      }
    default:
      return state;
  }
}