import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import SimpleRating from "../SimpleRating";

//Redux
import { connect } from "react-redux";

import "./ListaPreguntas.scss";
import SubPeguntas from "../SubPreguntas";

const Pregunta = ({ index, pregunta, guardarRespuestas, guardarPropuestas }) => {
  const [contenido, setContenido] = useState("");
  
  const guardarPropuesta = (id, propuestas) => {
    guardarPropuestas(id, propuestas)
  }

  useEffect(() => {
    guardarRespuestas(pregunta.id_pregunta_reporte, contenido);
  }, [contenido]);


  if (pregunta.propuestas) {
    return (
      <Form.Group className="pregunta">
        
          <p className={`${pregunta.id_obligacion === "1" ? "obligacion" : ""} d-flex`}>
            <span>{index + 1}.-</span> <span>{pregunta.pregunta}</span>
          </p>
          <p className='descripcion'>
            {pregunta.descripcion}
          </p>
        
        <div className="area-respuesta">
        <SimpleRating getStars={setContenido} />
        <SubPeguntas
          propuestas={pregunta.propuestas}
          guardarPropuesta={guardarPropuesta}
        />
        </div>
      </Form.Group>
    );
  }

  return (
    <div className="pregunta">
      <div className="pregunta-label">
        <p className={`${pregunta.id_obligacion === "1" ? "obligacion" : ""} d-flex`}>
          <span>{index + 1}.-</span> <span>{pregunta.pregunta}</span>
        </p>
        <p className='descripcion'>
          {pregunta.descripcion}
        </p>
      </div>
      <div className="area-respuesta">
        {pregunta.id_tp_pregunta === "1" ? (
          <SimpleRating getStars={setContenido} index={index} />
        ) : (
          <Form.Control
            as="textarea"
            placeholder="Escriba su respuesta"
            value={contenido}
            onChange={(e) => setContenido(e.target.value)}
          />
        )}
      </div>
    </div>
  );
};

const ListaPreguntas = (props) => {
  const { formulario, guardarRespuestas, guardarPropuestas } = props;
  const { preguntas } = formulario;

  return (
    <div className="lista-preguntas">
      {preguntas.length > 0
        ? preguntas.map((pregunta, index) => (
            <Pregunta
              key={index}
              index={index}
              pregunta={pregunta}
              guardarRespuestas={guardarRespuestas}
              guardarPropuestas={guardarPropuestas}
            />
          ))
        : "No hay preguntas"}
    </div>
  );
};

const mapStateToProps = (state) => ({
  formulario: state.formulario.formulario,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ListaPreguntas);
