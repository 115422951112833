import React, { useEffect } from "react";
import BasicLayout from "../../layout/BasicLayout/index";
import Loading from "../../components/Loading";
import HeadEncuesta from "../../components/HeadEncuesta";
import FormularioDiscente from "../../components/FormularioDiscente/FormularioDiscente";
import { useParams } from "react-router-dom";

//Redux
import { connect } from "react-redux";
import {
  obtenerFormulario,
  guardarRespuestasForm,
} from "../../_actions/formularioActions";

import "./EncuestaDiscente.scss";

const EncuestaDiscente = (props) => {
  const params = useParams();
  const { url } = params;

  const { loading, formulario, obtenerFormulario } = props;

  useEffect(() => {
    obtenerFormulario(url);
  }, []);

  if (loading) return <Loading />;

  return (
    <BasicLayout
      header={false}
      title={formulario.nombre_formulario || "Cargando..."}
    >
      <div className="formulario-encuesta">
        <div className="main-encuesta shadow">
          <HeadEncuesta
            title={formulario.nombre_formulario}
            descripcion={formulario.descripcion}
          />
          <div className="body-encuesta bg-white p-md-5 pt-md-1 p-2">
            <FormularioDiscente />
          </div>
          <div className="foot">
            © 2020 INEDI Posgrados &reg; Privacy & Cookies
          </div>
        </div>
      </div>
    </BasicLayout>
  );
};

const mapStateToProps = (state) => ({
  formulario: state.formulario.formulario,
  envioForm: state.formulario.envioForm,
  loading: state.formulario.loading,
});

const mapDispatchToProps = {
  obtenerFormulario,
  guardarRespuestasForm,
};

export default connect(mapStateToProps, mapDispatchToProps)(EncuestaDiscente);
