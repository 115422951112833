import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import configRouting from "./configRouting";
import { createBrowserHistory } from 'history';

const history = createBrowserHistory()

const Routing = () => {
  return (
    <Router history={history}>
      <Switch>
        {configRouting.map((route, index) => (
          <Route key={index} path={route.path} exact={route.exact}>
            <route.page />
          </Route>
        ))}
      </Switch>
    </Router>
  );
};

export default Routing;
